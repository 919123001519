<template>
  <a-row class="content-fix" type="flex">
    <a-col class="height100 flex-1">
      <a-row class="btn-row">
        <button
          class="tab-btn"
          :class="{ active: tab === 'content' }"
          @click="tab = 'content'"
        >
          <a-icon type="project" />Content
        </button>
      </a-row>

      <div v-show="tab === 'content'" class="main-tabs" type="card">
        <!-- Month Picker -->
        <a-row align="middle" class="flex-baseline input-area">
          <a-col
            :span="6"
            style="text-align: right; padding-right: 8px; color: #666"
          >
            Month (YYYY-MM):
          </a-col>
          <a-col :span="12">
            <a-month-picker
              v-model="form.month"
              :value="form.month"
              format="YYYY-MM"
              placeholder="Select month (YYYY-MM)"
              @change="onMonthChange"
              style="width: 100%"
            />
            <div v-if="monthError" style="color: red; font-size: 12px">
              Please select a valid month.
            </div>
          </a-col>
        </a-row>

        <!-- File Upload -->
        <a-row align="middle" class="flex-baseline">
          <a-col :span="6" style="text-align: right; padding-right: 8px">
            File:
          </a-col>
          <a-col :span="12">
            <a-button type="primary" @click="$refs.documentRef.click()">
              <a-icon type="upload" />Upload File
            </a-button>
            <input
              ref="documentRef"
              accept=".xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf"
              hidden
              type="file"
              @change="handleDocumentInput"
            />

            <!-- Show Selected File Name -->
            <div v-if="fileName" style="margin-top: 10px">
              <strong>Selected File:</strong> {{ fileName }}
            </div>

            <!-- Show Existing File Link (if applicable) -->
            <div v-if="form.file" style="margin-top: 10px">
              <a :href="form.file" target="_blank" style="color: #1890ff">
                <a-icon type="link" />Open Uploaded File
              </a>
            </div>
          </a-col>
        </a-row>

        <!-- Buttons -->
        <a-row style="margin-top: 20px">
          <a-col :span="6" />
          <a-col :span="12">
            <a-row>
              <a-button style="margin-right: 10px" @click="$router.go(-1)">
                <a-icon type="close" />Cancel
              </a-button>
              <a-button
                type="primary"
                @click="submitData"
                :disabled="monthError || !form.file"
              >
                <a-icon type="check" />Save
              </a-button>
            </a-row>
          </a-col>
        </a-row>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import moment from "moment"

export default {
  data() {
    return {
      form: {
        file: null, // Single file
        month: "" // YYYY-MM format
      },
      fileName: "", // To display the selected file name
      tab: "content",
      monthError: false
    }
  },
  methods: {
    onMonthChange(date) {
      this.form.month = date // Store the moment object
      this.monthError = !date // Set error if no date is selected
      if (this.monthError) {
        this.$message.warning("Please select a valid month.")
      }
    },

    handleDocumentInput(event) {
      const file = event.target.files[0]
      if (file) {
        this.form.file = file
        this.fileName = file.name
        this.$refs.documentRef.value = "" // Reset file input to allow re-selection
      }
    },

    async submitData() {
      if (this.monthError) {
        this.$message.error("Please fix the month format before submitting.")
        return
      }

      if (!this.form.file) {
        this.$message.warning("Please upload a file before submitting.")
        return
      }

      try {
        const formData = new FormData()
        formData.append("file", this.form.file) // Single file
        formData.append("month", moment(this.form.month).format("YYYY-MM")) // Format as YYYY-MM

        // Make POST request to the API endpoint
        const response = await this.$api.post(
          "/admin/common/map-appeal/import/",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" }
          }
        )

        const { data } = response
        if (data) {
          this.$message.success("Data successfully imported")
          this.$router.push({ name: "appealStatisticsMapServices" }) // Adjust the route as needed
        } else {
          this.$message.warning("Failed to import data.")
        }
      } catch (error) {
        console.error("Error submitting data:", error)
        this.$message.error("Error importing data")
      }
    }
  }
}
</script>

<style scoped>
/* Add any necessary styling here */
.content-fix {
  height: 100%;
}
.height100 {
  height: 100%;
}
.flex-1 {
  flex: 1;
}
.btn-row {
  margin-bottom: 16px;
}
.tab-btn {
  padding: 8px 16px;
  border: 1px solid #d9d9d9;
  background: #fff;
  cursor: pointer;
}
.tab-btn.active {
  background: #1890ff;
  color: #fff;
  border-color: #1890ff;
}
.main-tabs {
  padding: 16px;
  line-height: 50px;
}
.flex-baseline {
  align-items: baseline;
}
</style>
